var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Personel Düzenle")]),_c('v-card-text',[_c('v-tabs',{attrs:{"active-class":"active-tab"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Kişisel")]),_c('v-tab',[_vm._v("İletişim")]),_c('v-tab',[_vm._v("Detay")])],1),_c('v-tabs-items',{staticClass:"pt-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',_vm._l((["personel_no", "isim", "soyisim", "dogum_tarihi", "dogum_yeri", "meslek_id"]),function(item){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.form[item].text,"type":_vm.form[item].type,"outlined":"","dense":"","placeholder":_vm.form[item].text,"hide-details":""},model:{value:(_vm.form[item].data),callback:function ($$v) {_vm.$set(_vm.form[item], "data", $$v)},expression:"form[item].data"}})],1)}),1)],1),_c('v-tab-item',[_c('v-row',_vm._l((["telefon", "eposta", "ulke_id", "posta_kodu_id", "cadde"]),function(item){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.form[item].text,"type":_vm.form[item].type,"outlined":"","dense":"","placeholder":_vm.form[item].text,"hide-details":""},model:{value:(_vm.form[item].data),callback:function ($$v) {_vm.$set(_vm.form[item], "data", $$v)},expression:"form[item].data"}})],1)}),1)],1),_c('v-tab-item',[_c('v-row',_vm._l(([
                    "uyruk_id",
                    "sigorta_sirketi_id",
                    "kimlik_no",
                    "sosyal_guvenlik_no",
                    "kimlik_seri_no",
                    "kimlik_gecerlilik_tarihi",
                    "pasaport_no",
                    "pasaport_gecerlilik_tarihi",
                    "oturum_izin_no",
                    "oturum_izin_tarihi",
                    "guvenlik_belgesi"
                    ]),function(item){return _c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.form[item].text,"type":_vm.form[item].type,"outlined":"","dense":"","placeholder":_vm.form[item].text,"hide-details":""},model:{value:(_vm.form[item].data),callback:function ($$v) {_vm.$set(_vm.form[item], "data", $$v)},expression:"form[item].data"}})],1)}),1)],1)],1)],1),_c('v-card-actions',{staticClass:"pl-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.Olustur}},[_vm._v(" "+_vm._s(_vm.$t('Personeller.kaydet'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }