<template>
  <div>
    <v-row class="match-height">
      <!-- Multiple Column -->
      <v-col cols="12">
        <v-card>
          <v-card-title>Personel Düzenle</v-card-title>
          <v-card-text>
            <v-tabs v-model='tab' active-class='active-tab'>
              <v-tab>Kişisel</v-tab>
              <v-tab>İletişim</v-tab>
              <v-tab>Detay</v-tab>
            </v-tabs>
            <v-tabs-items v-model='tab' class='pt-5'>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in ["personel_no", "isim", "soyisim", "dogum_tarihi", "dogum_yeri", "meslek_id"]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in ["telefon", "eposta", "ulke_id", "posta_kodu_id", "cadde"]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in [
                      "uyruk_id",
                      "sigorta_sirketi_id",
                      "kimlik_no",
                      "sosyal_guvenlik_no",
                      "kimlik_seri_no",
                      "kimlik_gecerlilik_tarihi",
                      "pasaport_no",
                      "pasaport_gecerlilik_tarihi",
                      "oturum_izin_no",
                      "oturum_izin_tarihi",
                      "guvenlik_belgesi"
                      ]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class='pl-2'>
            <v-col cols="12">
              <v-btn color="primary" @click='Olustur'>
                {{ $t('Personeller.kaydet') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiWindowClose } from '@mdi/js'

export default {
  name: 'PersonelGuncelle',
  data() {
    return {
      tab: null,
      MeslekSearch: '',
      SigortaSirketiSearch: '',
      UyrukSearch: '',
      tabs: [this.$t('Personeller.kisisel'), this.$t('Personeller.adres'), this.$t('Personeller.resmi')],
      data: {
        uyruk: '',
      },
      icons: {
        mdiWindowClose,
      },
      form: [],
    }
  },
  computed: {
    Meslekler() {
      return this.$store.state.MeslekGruplari.MeslekGruplari
    },
    Ulkeler() {
      return this.$store.state.Ulkeler.ulkeler
    },
    Uyruklar() {
      return this.$store.state.Uyruklar.Uyruklar
    },
    Postakodlari() {
      const PostaKodlariVeSehirler = this.$store.state.Sehirler.Sehirler.map(item => ({
        id: item.id,
        posta_kodu: `${item.posta_kodu} > ${item.sehir}`,
      }))

      return PostaKodlariVeSehirler
    },
    SigortaSirketleri() {
      return this.$store.state.SigortaSirketleri.SigortaSirketleri
    },
  },
  mounted() {
    this.$store.state.createDialog = false
  },
  created() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route?.params.hasOwnProperty('personel')) {
      this.$store.dispatch('Action', { name: 'EvrakTipleri/EvrakTipleriListesi' })
      this.setForm()
    } else {
      this.$router.push({ name: 'personel-listesi' })
    }
  },
  methods: {
    async Olustur() {
      const veri = {}
      await Object.keys(this.form).forEach((key)=> {
        const item = this.form[key]
        veri[item.value] = item.data
      })
      veri.id = this.$route.params.id
      this.$store.dispatch('Action', { name: 'Personeller/PersonellerGuncelle', data: veri }).then(() => {
        this.$router.push({ name: 'personel-listesi' })
      })
    },
    filter(item, queryText, itemText) {
      // eslint-disable-next-line no-param-reassign
      queryText = queryText.replace(/[İı]/g, 'i')
      // eslint-disable-next-line no-param-reassign
      itemText = itemText.replace(/[İı]/g, 'i')

      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    Tarih(date) {
      if (date) {
        const tarih = date.split('.')
        const gun = tarih[0]
        const ay = tarih[1]
        const yil = tarih[2]
        console.log(`${yil}-${ay}-${gun}`)

        return `${yil}-${ay}-${gun}`
      }

      return ''
    },
    setForm() {
      this.form = {
        personel_no: { type: 'text', data: this.$route.params.personel.personel_no, text: this.$t('Personeller.personel_no'), value: 'personel_no' },
        isim: { type: 'text', data: this.$route.params.personel.isim, text: this.$t('Personeller.isim'), value: 'isim' },
        soyisim: { type: 'text', data: this.$route.params.personel.soyisim, text: this.$t('Personeller.soyisim'), value: 'soyisim' },
        dogum_tarihi: { type: 'date', data: this.$route.params.personel.dogum_tarihi, text: this.$t('Personeller.dogum_tarihi'), value: 'dogum_tarihi' },
        dogum_yeri: { type: 'text', data: this.$route.params.personel.dogum_yeri, text: this.$t('Personeller.dogum_yeri'), value: 'dogum_yeri' },
        telefon: { type: 'text', data: this.$route.params.personel.telefon, text: this.$t('Personeller.telefon'), value: 'telefon' },
        eposta: { type: 'text', data: this.$route.params.personel.eposta, text: this.$t('Personeller.eposta'), value: 'eposta' },
        meslek_id: { type: 'text', data: this.$route.params.personel.meslek_id, text: this.$t('Personeller.meslek_id'), value: 'meslek_id' },
        uyruk_id: { type: 'text', data: this.$route.params.personel.uyruk_id, text: this.$t('Personeller.uyruk_id'), value: 'uyruk_id' },
        ise_giris_tarihi: { type: 'date', data: this.$route.params.personel.ise_giris_tarihi, text: this.$t('Personeller.ise_giris_tarihi'), value: 'ise_giris_tarihi' },
        ulke_id: { type: 'text', data: this.$route.params.personel.ulke_id, text: this.$t('Personeller.ulke_id'), value: 'ulke_id' },
        posta_kodu_id: { type: 'text', data: this.$route.params.personel.posta_kodu_id, text: this.$t('Personeller.posta_kodu_ve_sehir'), value: 'posta_kodu_id' },
        cadde: { type: 'text', data: this.$route.params.personel.cadde, text: this.$t('Personeller.cadde'), value: 'cadde' },
        sigorta_sirketi_id: { type: 'text', data: this.$route.params.personel.sigorta_sirketi_id, text: this.$t('Personeller.sigorta_sirketi_id'), value: 'sigorta_sirketi_id' },
        kimlik_no: { type: 'text', data: this.$route.params.personel.kimlik_no, text: this.$t('Personeller.kimlik_no'), value: 'kimlik_no' },
        sosyal_guvenlik_no: { type: 'text', data: this.$route.params.personel.sosyal_guvenlik_no, text: this.$t('Personeller.sosyal_guvenlik_no'), value: 'sosyal_guvenlik_no' },
        kimlik_seri_no: { type: 'text', data: this.$route.params.personel.kimlik_seri_no, text: this.$t('Personeller.kimlik_seri_no'), value: 'kimlik_seri_no' },
        kimlik_gecerlilik_tarihi: { type: 'date', data: this.$route.params.personel.kimlik_gecerlilik_tarihi, text: this.$t('Personeller.kimlik_gecerlilik_tarihi'), value: 'kimlik_gecerlilik_tarihi' },
        pasaport_no: { type: 'text', data: this.$route.params.personel.pasaport_no, text: this.$t('Personeller.pasaport_no'), value: 'pasaport_no' },
        pasaport_gecerlilik_tarihi: { type: 'date', data: this.$route.params.personel.pasaport_gecerlilik_tarihi, text: this.$t('Personeller.pasaport_gecerlilik_tarihi'), value: 'pasaport_gecerlilik_tarihi' },
        oturum_izin_no: { type: 'text', data: this.$route.params.personel.oturum_izin_no, text: this.$t('Personeller.oturum_izin_no'), value: 'oturum_izin_no' },
        oturum_izin_tarihi: { type: 'date', data: this.$route.params.personel.oturum_izin_tarihi, text: this.$t('Personeller.oturum_izin_tarihi'), value: 'oturum_izin_tarihi' },
        guvenlik_belgesi: { type: 'text', data: this.$route.params.personel.guvenlik_belgesi, text: this.$t('Personeller.guvenlik_belgesi'), value: 'guvenlik_belgesi' },
      }
    },
  },

}
</script>

<style lang='sass' scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical)
  box-shadow: none !important
</style>
